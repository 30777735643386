import InfiniteScroll from '../components/InfiniteScroll';
import ParallaxStar from '../components/ParallaxStar';

const Skills = () => {

    return (
        <main>
            <ParallaxStar>
                    <InfiniteScroll />
            </ParallaxStar>
        </main>
    );
}

export default Skills